import { useEffect, useState } from "react"
import React from "react"
import { Router } from "next/router"

import { useTranslation } from "lib/i18n/translation"
import { useLanguages } from "lib/languages"

import LogoFr from "static/assets/images/logo-fr.svg"
import LogoEn from "static/assets/images/logo-en.svg"
import LogoDe from "static/assets/images/logo-de.svg"

import SkeletonLink from "components/common/skeleton-link"
import SearchBar from "./searchbar"
import { Navigation } from "./header/navigation"
import { MainMenu } from "./header/main-menu"

const Header = () => {
	const { c } = useTranslation("common")
	const { current } = useLanguages()
	const [expanded, setExpanded] = useState(false)

	const logos = {
		fr: LogoFr,
		en: LogoEn,
		de: LogoDe
	}
	const Logo = logos[current]

	const toggleExpand = () => {
		if (expanded) {
			document.body.classList.remove("expanded")
		} else {
			document.body.classList.add("expanded")
		}
		setExpanded(() => !expanded)
	}

	useEffect(() => {
		const routeChange = () => {
			if (expanded) toggleExpand()
		}
		Router.events.on('routeChangeComplete', routeChange)
		return () => {
			Router.events.off('routeChangeComplete', routeChange)
		}
	}, [expanded])

	return (
		<header>
			<SkeletonLink href="/">
				<a title={c("back-homepage")} className="lacinetek">
					<Logo title={c("lacinetek-logo")} />
					<span className="label">{c("cinetek-title")}</span>
				</a>
			</SkeletonLink>
			<MainMenu />
			<nav id="navigation" className={ expanded ? "expanded" : "" }>
				<SearchBar className="only-desktop" autoCompleteInForm={true} />
				<Navigation />
				<Navigation mobile={true}	/>
			</nav>
			<a
				href="#"
				onClick={e => { e.preventDefault(); toggleExpand() }}
				rel="nofollow noopener"
				title={c("display-menu")}
				id="navigation-expand"
				className={ expanded ? "expanded" : ""}
			>
				<span className="label">{c("display-menu")}</span>
			</a>
		</header>
	)
}

export default Header
